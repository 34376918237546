import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ITab, ITabs } from '../../types/customizer';
import './index.scss';
// import URLs from '../../../../utils/url';
import { TABS_TO_AVOID, getRowContent } from '../../../utils/summary';
import { useParams } from 'react-router-dom';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';
import { useRingCustomizerContext } from '../../context/RingCustomizerContext';

type PrintProps = {
  tabs: ITabs;
  product: any;
  image:string;
};


const PrintSummary = React.forwardRef((props:PrintProps, ref) => {
    const { tabs, product, image } = props;
    const {subpath} = useRingCustomizerContext();

    const imageStr = `${process.env.REACT_APP_ASSET_URL}${image}`;
    let imageURL = `${imageStr}${imageStr.indexOf('?') !== -1 ? '&' : '?'}image_size=1200&rand=${Math.random().toString(12)}`;

    const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
    if(!styles.watermarkBrand) {
        imageURL += '&watermark=false'
    }
    if (imageURL.indexOf('watermarkBrand=emblem') !== -1 && subpath !== 'customize') {
        const watermark = styles.watermarkBrand;
        imageURL = imageURL.replace('emblem', watermark);
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        const printButton = document.getElementById('print-btn');

        if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && printButton) {
            event.preventDefault();
            printButton.click();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        // @ts-ignore
        <div ref={ref} className="print-summary tw-py-10 tw-px-4 tw-mx-auto tw-w-[calc(100%-2cm)]">
            <div className="tw-flex tw-justify-center tw-mb-4">
                <img className="tw-h-[285px]" src={imageURL} alt="" />
            </div>
            <p>
                {product?.description}
            </p>
            <div className="tw-mt-3 tw-text-lg tw-font-bold">Required</div>
            <div className="print-summary-container tw-mt-3 tw-ml-3">
                <div className="tw-mt-1">
                    <p>Magic Number</p>
                    <p>{product?.magic_number}</p>
                </div>
                {tabs
                    ?.filter((tab: ITab) => ['ringSize'].includes(tab.eventKey))
                    .map((tab: ITab, index) => (
                        <React.Fragment key={index}>{getRowContent(tab, index)}</React.Fragment>
                    ))}
                {tabs
                    ?.filter((tab: ITab) => ['engraving'].includes(tab.eventKey))
                    .map((tab: ITab, index) => (
                        <React.Fragment key={index}>{getRowContent(tab, index)}</React.Fragment>
                    ))}
            </div>
            <div className="tw-mt-3 tw-text-lg tw-font-bold">Additional Details</div>
            <div className="print-summary-container tw-mt-3 tw-ml-3">
                <div className="tw-mt-1">
                    <p>Style</p>
                    <p>{product?.sku}</p>
                </div>
                {tabs
                    ?.filter(
                        (tab: ITab) =>
                            !TABS_TO_AVOID.includes(tab.eventKey)
                    )
                    .map((tab: ITab, index) => (
                        <React.Fragment key={index}>
                            {getRowContent(tab, index, { print: true })}
                        </React.Fragment>
                    ))}
            </div>
            <div className="tw-text-right tw-mt-20">
                Signature: _________________________
            </div>
        </div>
    );
});

export default connect(null, null, null, { forwardRef: true })(PrintSummary);

