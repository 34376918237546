import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";
import clsx from "clsx";
import { ICategoryOption, IOption, ISwatchContent } from "../../types/customizer";
import SwatchOption from "../SwatchOption";
import "../../../../node_modules/swiper/swiper.min.css";

import './sizeFilter.scss';
import { useResizeDetector } from "react-resize-detector";

export interface ISizeFilterProps extends ISwatchContent {
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
  options: Array<IOption>; // override options params
}

const SizeFilter = ({ label, showDefaultTitle, options, hasSwatch, displayShowMoreOption, fieldType,
  selectedOption, meta, warning, onChange, isEnabled, isFetching, innerTab = 1 }:ISizeFilterProps) => {

  const { width, ref } = useResizeDetector();

  let timeoutId: any = null;

  const [isChanging, setIsChanging] = useState(''); 
  const [swiper, setSwiper] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState(() => {
    const index = options.findIndex(item => item.selected);
    return index;
  });

  useEffect(() => {
    if(!isFetching) {
      setIsChanging('');
    }
  }, [isFetching]);

  useEffect(() => {
    const index = options.findIndex(item => item.selected);
    setActiveIndex(index);
  }, [options]);

  useEffect(() => {
    swiper && swiper.slideToLoop(activeIndex);
  }, [activeIndex, swiper, options.length])

    useEffect(() => {
    if (width) {
      ref.current.style.width = `${width + 1}px`;
      setTimeout(() => {
        ref.current.style.width = `${width}px`;
      }, 100);
    }
  }, [!!width]);

  const handleChange = (updatedObj: {[name: string]: string}) => {
    setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
    onChange(updatedObj);
  }

  const handleSlideChange = (e: any) => {
    /**
     * On Css mode while slider, onSlideChange trigger everytime till animation ends, So call timeout after 500ms
     */
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const index = e.realIndex;
      if(index !== null && index !== undefined && index !== activeIndex) {
        setActiveIndex(index);
        const option = options[index];
        handleChange({[fieldType]: option.sku})
      }
    }, 500)
  }

  return (
    <div className="tw-flex tw-justify-center tw-pb-2 swatch-filter-border" style={{height: `calc(100% - ${ 45 * innerTab }px)`}} ref={ref}>
      <div className='tw-w-full sm:tw-w-full tw-px-4'>
        <div className='tw-mb-2 tw-mt-5 xs:tw-text-center sm:tw-text-left'>
          <div className="tw-inline-block xs:tw-w-4/6 sm:tw-w-4/6 tw-text-base tw-text-primary-color">Select your finger size</div>
        </div>
        <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-pb-2" ref={ref} style={{height: 'calc(100% - 100px)'}}>
          {/* { width && width < 640 && <> */}
            <Swiper
              onSwiper={setSwiper}
              slidesPerView="auto"
              slideToClickedSlide
              spaceBetween={1}
              loop={true}
              centeredSlides={true}
              onSlideChange={handleSlideChange}
              initialSlide={activeIndex}
              cssMode={width && width < 640 ? true : false}
              grabCursor={true}
              mousewheel={true}
              modules={[Mousewheel]}
            >
              {options.map((option: IOption | ICategoryOption, index: number) =>
                <SwiperSlide key={index} className={clsx({'active-slide': index === activeIndex })}>{option.name}</SwiperSlide>)
              }
            </Swiper>
          {/* </>} */}
          {/* {width && width > 640 && <div className="tw-flex tw-flex-wrap">
            {options.map((option: IOption, index: number) =>
              <SwatchOption
                key={index}
                {...option}
                hasSwatch={hasSwatch}
                displayShowMoreOption={displayShowMoreOption}
                fieldType={fieldType}
                onChange={handleChange}
                selectedOption={selectedOption}
                isChanging={isChanging}
            />)}
          </div>} */}
        </div>
      </div>
    </div>
  )
}

export default SizeFilter;
