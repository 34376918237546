import SwatchFilter from "../app/components/SwatchFilter";
import { ITab, ITabs } from "../app/types/customizer";
import { getFilterMetaData, getSelectedOption, getWarning } from "./commonRingUtils";

export const getShapeItems = (product: any) => {
  const items: ITabs = [
    {
      title: 'Profile',
      eventKey: 'profile',
      component: SwatchFilter,
      content: {
        label: 'Ring Profile',
        hasSwatch: true,
        showDefaultTitle: false,
        displayShowMoreOption: false, // show more removed after review
        isEnabled: product.profile?.length > 0,
        fieldType: "profile",
        options: product.profile,
        selectedOption: getSelectedOption(product.profile),
        meta: getFilterMetaData(product, 'profile'),
        warning: getWarning(product, 'profile')
      }
    },
    {
      title: 'Width',
      eventKey: 'width',
      component: SwatchFilter,
      content: {
        label: 'Ring Width',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: product.width?.length > 0,
        fieldType: "width",
        options: product.width,
        selectedOption: getSelectedOption(product.width),
        meta: getFilterMetaData(product, 'width'),
        warning: getWarning(product, 'width')
      }
    },
    {
      title: 'Weight',
      eventKey: 'weight',
      component: SwatchFilter,
      content: {
        label: 'Ring Weight',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: product.weight?.length > 0,
        fieldType: "weight",
        options: product.weight,
        selectedOption: getSelectedOption(product.weight),
        meta: getFilterMetaData(product, 'weight'),
        warning: getWarning(product, 'weight')
      }
    }
  ];
  const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}