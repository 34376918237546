import SwatchFilter from "../app/components/SwatchFilter";
import { ITab, ITabs } from "../app/types/customizer";
import { getFilterMetaData, getSelectedOption, getWarning } from "./commonRingUtils";

export const getArrangementItems = (product: any) => {
  const items: ITabs = [
    {
      title: 'Stone Quantity',
      eventKey: 'stoneQuality',
      component: SwatchFilter,
      content: {
        label: 'Stone Quantity',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: product.stone_quantity?.length > 0,
        fieldType: "stone_quantities",
        options: product.stone_quantity || [],
        selectedOption: getSelectedOption(product.stone_quantity),
        meta: getFilterMetaData(product, 'stone_quantity'),
        warning: getWarning(product, 'stone_quantity')
      }
    },
    {
      title: 'Carat Weight',
      eventKey: 'caratWeight',
      component: SwatchFilter,
      content: {
        label: 'Ring Total Carat Weight',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: product.variant?.length > 0,
        fieldType: "variants",
        options: product.variant || [],
        selectedOption: getSelectedOption(product.variant),
        meta: getFilterMetaData(product, 'variant'),
        warning: getWarning(product, 'variant')
      }
    },
    {
      title: 'Color Arrangement',
      eventKey: 'colorArrangement',
      component: SwatchFilter,
      content: {
        label: 'Color Arrangement',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: product.color_arrangement?.length > 0,
        fieldType: "color_arrangements",
        options: product.color_arrangement || [],
        selectedOption: getSelectedOption(product.color_arrangement),
        meta: getFilterMetaData(product, 'color_arrangement'),
        warning: getWarning(product, 'color_arrangement')
      }
    }
  ];
    const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}