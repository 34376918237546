import React from 'react';
import clsx from 'clsx';
import { ITab } from '../app/types/customizer';
import { getSelectedInfo } from './commonRingUtils';

const FIELD_TO_AVOID = ['None Tab', 'Inlay Style'];

export const TABS_TO_AVOID = ['summaryInfo', 'summary', 'ringSize', 'engraving', 'data'];
export const getRowContent = (tab: ITab, index: number, options: { [key: string]: boolean } = {}) => {
    const selectedOption = getSelectedInfo(tab);
    const selectedText = selectedOption?.text || '';
    let title =
  typeof tab.title === 'string'
      ? tab.title
      : typeof tab.title === 'function' && tab.title();

    if (tab.eventKey === 'outsideFeature_2') { title = 'Secondary Outside Feature'; }

    const classNames = clsx({
        'tw-mt-1': index !== 3 && index !== 4 && index < 5 && !options.print,
        'tw-mt-3 lg:tw-mt-8': (index === 3 || index === 4 || index >= 5) && !options.isPDP && !options.print,
        'tw-mt-3 lg:tw-mt-4': (index === 3 || index === 4 || index >= 5) && options.isPDP,
        'tw-mt-0': index !== 3 && index !== 4 && index < 5 && options.print,
        'tw-mt-3': (index === 3 || index === 4 || index >= 5) && options.print,
    });

    if (tab.eventKey === 'shape') {
        const [profile, width] = selectedText.split('-');
        return (
            <>
                <div className={classNames}>
                    <p>Profile</p>
                    <p>{profile}</p>
                </div>
                <div className={classNames}>
                    <p>Width</p>
                    <p>{width}</p>
                </div>
            </>
        );
    }
    if (['outsideFeature_1', 'outsideFeature_2'].includes(tab.eventKey)) {
        return (
            <>
                <div className={classNames}>
                    <p>{title}</p>
                    <p>{tab.selectedFeature || 'None'}</p>
                </div>
                {tab.items
                    ?.filter(
                        (item) => !FIELD_TO_AVOID.includes(item.content?.label || '')
                    )
                    .map((item) => (
                        <div>
                            <p>{item?.content?.label?.replace('Inlay Width', 'Inlay Style')}</p>
                                <p>{(item?.content?.selectedOption?.name || 'None').replace(/(\d+)mm/g, '$1 mm')}</p>
                        </div>
                    ))}
            </>
        );
    }

    if (tab.eventKey === 'finish' && selectedText.includes(' - ')) {
        const [firstFinish, secondFinish] = selectedText.split(' - ');
        return (
            <>
                <div className={classNames}>
                    <p>First Finish</p>
                    <p>{firstFinish}</p>
                </div>
                <div className={classNames}>
                    <p>Second Finish</p>
                    <p>{secondFinish}</p>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={classNames}>
                <p>{title}</p>
                <p>{selectedText}</p>
            </div>

            {tab.eventKey === 'engraving' && selectedOption?.engravingText && (
                <div className="tw-mt-1">
                    <p>Engraving Text</p>
                    <p>{selectedOption.engravingText}</p>
                </div>
            )}

        </>
    );
};
