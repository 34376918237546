import  ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { ToastContainer } from 'react-toastify';
import App from './app';
import './index.scss';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import '../node_modules/rc-tabs/assets/index.css';
import "../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import '../node_modules/react-toastify/dist/ReactToastify.css';
import './assets/scss/rc-tab.override.css';
import "./assets/scss/image-gallery.override.css";
import 'react-rangeslider/lib/index.css';
import { createBrowserHistory } from 'history';

if (process.env.NODE_ENV === 'development') {
  require('./assets/tailwind/output.css')
} else if (process.env.NODE_ENV === 'production') {
  require('./assets/tailwind/input.css')
}

const container = document.getElementById('root')!;

// Create a client
const queryClient = new QueryClient()
if(process.env.REACT_APP_LOGROCKET_APP_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
    console: {isEnabled: true}
  });
  
  LogRocket.getSessionURL(sessionURL => {
    console.log('sessionURL', sessionURL);
  });
}

export const history = createBrowserHistory();

setupLogRocketReact(LogRocket);

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
        <ToastContainer theme='colored'/>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  </Provider>, 
  container);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
