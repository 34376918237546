import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ISwatchContent } from '../../types/customizer';
import SwatchCategory from '../SwatchCategory';

export interface ISwatchFilterProps extends ISwatchContent {
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
}

const SwatchFilter = ({ label, showDefaultTitle, options, hasSwatch, displayShowMoreOption, fieldType,
  selectedOption, meta, warning, onChange, isEnabled, isFetching, innerTab = 1 }:ISwatchFilterProps) => {

  const [isChanging, setIsChanging] = useState('');

  useEffect(() => {
    if(!isFetching) {
      setIsChanging('');
    }
  }, [isFetching]);

  const handleChange = (updatedObj: {[name: string]: string}) => {
    setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
    onChange(updatedObj);
  }

  return (
    <div className={clsx("tw-flex tw-justify-center tw-pb-2 tw-h-full", {'swatch-filter-border' : innerTab === 1})}>
      <div className='tw-w-full sm:tw-w-full tw-px-4'>
        {/* <If condition={showDefaultTitle}>
          <div className="tw-mb-2">
            <span className="tw-text-base tw-text-black tw-font-semibold">Choose {label}</span>
            <If condition={meta?.more_link && !meta?.description}>
              <a href={`/${meta?.more_link}`} className="tw-text-blue tw-font-semibold tw-ml-2" target="_blank" rel='noreferrer'>
                {meta?.link_label} <img className="tw-cursor-pointer tw-inline tw-w-4 tw-pl-1" src={rightArrow} alt="right_arrow" />
              </a>
            </If>
          </div>
        </If>
        <If condition={meta?.description}>
          <div className="tw-mb-2">
            <span className="tw-text-base tw-text-black tw-font-normal">{meta?.description}</span>
            <If condition={meta?.more_link && meta?.description}>
              <a href={`/${meta?.more_link}`} className="tw-text-blue tw-font-semibold tw-ml-2" target="_blank" rel='noreferrer'>
                {meta?.link_label} <img className="tw-cursor-pointer tw-inline tw-w-4 tw-pl-1" src={rightArrow} alt="right_arrow" />
              </a>
            </If>
          </div>
        </If>
        <If condition={selectedOption}>
          <div className="tw-mb-4">
            <span className="tw-text-base tw-text-black tw-font-normal">Selected {label} : </span>
            <span className="tw-font-semibold">{selectedOption.name}</span>
          </div>
        </If> */}
        <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-h-full tw-pb-2">
          {/* Pass default values for those fields  ( type / name / swatch_url / index )  */}
            <SwatchCategory
              type='category'
              name=''
              swatch_url=''
              items={options}
              hasSwatch={hasSwatch}
              displayShowMoreOption={displayShowMoreOption}
              fieldType={fieldType}
              onChange={handleChange}
              selectedOption={selectedOption}
              index={0}
              isChanging={isChanging}
              isEngraving={false}
              rootCategory={true}
            />
        </div>
      </div>
    </div>
  )
}

export default SwatchFilter
