import clsx from 'clsx';
import { useEffect, useState } from "react";
// @ts-ignore
import Tabs, { TabPane } from 'rc-tabs';
import SwatchFilter from "../SwatchFilter";

import leftArrow from '../../../assets/images/Left-main-nav-arrow.svg';
import rightArrow from '../../../assets/images/Right-main-nav-arrow.svg';
import { ITab, ITabs } from "../../types/customizer";


type IProps = {
  tabs: ITabs;
  tabStyle?: 'single' | 'box' | 'underline';
  onChange: (updated: any) => void;
  isFetching: boolean;
  activeKey: string;
  onChangeParentActiveKey?: (key: string) => void;
  innerTab?: number;
  images?:string[];
}

const AppTabs = ({ tabs, tabStyle = 'underline', onChange, isFetching, activeKey, onChangeParentActiveKey, innerTab = 1, images }: IProps) => {
  const [keyList, setKeyList] = useState<string[]>([]);
  const [key, setKey] = useState<string>(activeKey);

  useEffect(() => {
    const list = tabs.map(t => t.eventKey);
    setKeyList(list);
  }, [tabs]);

  useEffect(() => {
    if(!keyList.includes(key)) {
      setKey(keyList[0]);
    }
  }, [keyList, key])

  useEffect(() => {
    setKey(activeKey);
  }, [activeKey]);

  const isInitial = () => {
    const currentIndex = keyList.indexOf(key);
    return currentIndex === 0
  }

  const isLast = () => {
    const currentIndex = keyList.indexOf(key);
    return currentIndex === (keyList.length - 1);
  }

  const handlePrev = () => {
    const currentIndex = keyList.indexOf(key)
    let index = currentIndex - 1;
    if(index < 0) {
      index = keyList.length - 1;
    }
    setKey(keyList[index]);
    onChangeParentActiveKey && onChangeParentActiveKey(keyList[index])
  }

  const handleNext = () => {
    const currentIndex = keyList.indexOf(key)
    let index = currentIndex + 1;
    if(index >= keyList.length) {
      index = 0;
    }
    setKey(keyList[index]);
    onChangeParentActiveKey && onChangeParentActiveKey(keyList[index])
  }

  const handleTabClick = (selectedOpt: string) => {
    if(keyList.length) {
      const currentIndex = keyList.indexOf(selectedOpt)
      const currentTab = tabs[currentIndex];
      if(currentTab?.actionPayload) {
        onChange(currentTab?.actionPayload)
      }
    }
  }

  /*
  const getTabExtraContent = () => {
    return tabStyle === 'single' ? {
      left: <div className='tw-flex tw-align-middle xs:tw-mr-3 sm:tw-mr-0'><img className="tw-cursor-pointer" src={leftArrow} alt="left_arrow" onClick={handlePrev}/></div>,
      right: <div className='tw-flex tw-align-middle xs:tw-ml-3 sm:tw-ml-0'><img className="tw-cursor-pointer" src={rightArrow} alt="right_arrow" onClick={handleNext}/></div>
    } : null
  }*/

  const getTabTitle = (tab: ITab) => {
    const isActive = tab.eventKey === key;
    return (
      <>
        <div className={clsx({
          'rc-single-tab tw-flex tw-justify-between sm:tw-hidden': tabStyle === 'single',
          'tw-hidden': tabStyle === 'single' && !isActive,
          'rc-box rc-box-tab-case': tabStyle === 'box',
          'rc-box-active': tabStyle === 'box' && isActive,
          'rc-underline': tabStyle === 'underline',
          'rc-underline-active': tabStyle === 'underline' && isActive
        })}>
          {tabStyle === 'single' &&
            <img className={clsx('tw-cursor-pointer tw-w-4 tw-inline-block', { 'tw-invisible': isInitial() })} src={leftArrow}
              alt="left_arrow" onClick={handlePrev}/>}
          <span className='tw-inline-block tw-text tw-min-w-[20px]'>
            {typeof tab.title === 'string' && tab.title}
            {typeof tab.title === 'function' && tab.title(isActive)}
          </span>
          {tabStyle === 'single' && <img className={clsx('tw-cursor-pointer tw-w-4 tw-inline-block', { 'tw-invisible': isLast() })} src={rightArrow} alt="right_arrow" onClick={handleNext}/>}
        </div>
      </>
    )
  }

  const getActiveTab = (items: ITab[]) => {
    const selectedTab = items.find(tab => tab.isSelected);
    let key = '';
    if(selectedTab) {
      key = selectedTab.eventKey;
    } else {
      key = items?.[0]?.eventKey || '';
    }
    return key;
  }

  const getTabContent = (tab: any) => {
    const Component = tab.component || SwatchFilter;
    return <Component {...tab.content} onChange={onChange} isFetching={isFetching} innerTab={innerTab} images={images} tabs={tabs}/>
  }

  return (
    <Tabs
      activeKey={key}
      onChange={setKey}
      onTabClick={handleTabClick}
      // tabBarStyle={['box', 'single'].includes(tabStyle) ? { borderBottom: '2px solid #F7F6F4' } : { }}
      className={clsx('tw-border-hidden', {'single-tab-container': tabStyle === 'single'})}
    >
      {tabs.map((tab: ITab) => (
        <TabPane key={tab.eventKey} tab={getTabTitle(tab)} forceRender={tab.eventKey === 'summary'}>
          {tab.items &&
            <AppTabs
              tabs={tab.items}
              tabStyle={tab.tabStyle}
              activeKey={getActiveTab(tab.items)}
              isFetching={isFetching}
              onChange={onChange}
              innerTab={innerTab + 1}
              images={images}
            />}
          {tab.content && getTabContent(tab)}
        </TabPane>
      ))}
    </Tabs>
  );
}

export default AppTabs;
