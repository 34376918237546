import LashbrookLogo from '../assets/images/LB_Logoblue.png'
import EmblemLogo from '../assets/images/emblem-black-small.svg';
// import HelzbergLogo from '../assets/images/helzberg.svg';
import HelzbergLogoPink from '../assets/images/helzberg-pink.svg';
import JohnAtencioLogo from '../assets/images/john-atencio-logo.svg';
import BellmansLogo from '../assets/images/Bellmans_logo.png';
import MaviloLogo from '../assets/images/Mavilo-Logo.png';
import ThollotLogo from '../assets/images/Thollot_logo.png';
import LmdLogo from '../assets/images/lmdlogo.svg';
import schubachLogo from '../assets/images/schubach_logo.svg';
import TQDiamondsLogo from '../assets/images/TQDiamonds_Logo.svg';
import ZaanaLogo from '../assets/images/zaana-logo.png';
import TalismanLogo from '../assets/images/talisman.png';
import HerzogLogo from '../assets/images/herzog-logo.png';
import DublinLogo from '../assets/images/dublin-village-logo.png';
import JonesLogo from '../assets/images/logo-jones-bros.png';
import MichelAndSonsLogo from '../assets/images/MichelAndSonsLogo_logo.avif';
import BellaCosaLogo from '../assets/images/bella-cosa-logo.png';
import RobertPalmLogo from '../assets/images/robert-palma-logo.webp';
import ArtofJewelsLogo from '../assets/images/art-of-jewels-logo.png';
import CharlestonLogo from '../assets/images/Charleston-Alexander-Jewelers-logo.png';
import ReisNicholosLogo from '../assets/images/reis-nichols-logo.svg';
import cartersLogo from '../assets/images/carters-logo.png';
import shanecoLogo from '../assets/images/ShaneCo-logo.svg';
import YatesLogo from '../assets/images/yates-logo.png';
import DalesLogo from '../assets/images/dales-jewelry-logo.webp';
import MillsLogo from '../assets/images/mills-logo.png';
import JDSmithLogo from '../assets/images/jd-smith-logo.avif';
import diamondsByDawnLogo from '../assets/images/diamondsbydawn-logo.avif';
import nasrLogo from '../assets/images/nasr-logo.png';


import { IParams } from '../app/components/RingCustomizerHOC';
import { matchRoutes } from 'react-router-dom';
import { MENS_PATHS, WOMENS_PATHS } from '../routes';

export const isEmpty = (value: any) => value === null || value === undefined || value === '' ||
                        (Array.isArray(value) && value.length === 0);

export type AnyObject = { [key: string]: any };

export const urlToParams = (ring_url: string) => {
  const [pathString, queryString] = ring_url ? ring_url.split('?') : [];

  const routePaths = [
    ...MENS_PATHS,
    ...WOMENS_PATHS
  ];

  const matchObj = matchRoutes(routePaths.map(path => ({ path })), ring_url);
  const searchParams = new URLSearchParams(queryString);
  const queryParams: IParams['queryParams'] = Object.fromEntries(searchParams.entries());
  const pathParams: IParams['pathParams'] = matchObj ? matchObj[0].params : {};
  return { pathParams, queryParams };
};


export const DEFAULT_STYLES = {
  brandColor: '#2a77a7',
  brandlogo: `url(${LashbrookLogo})`,
  watermarkBrand: 'lashbrook',
  tabIcon: 'lashbrook-favicon.ico',
  tabTitle: '',
  enablePoweredBy: true,
  enableDefaultDone: true,
  enableMagicNoSearch: true,
  isStage: true,

  primaryFont: 'Reckless',
  secondaryFont: 'Neue Haas Unica Pro',
  primaryColor: '#003053',
  secondaryColor: '#003053',
  tertiaryColor: '#ffffff',
  primaryBgColor: '#f2f5f7',
  secondaryBgColor: '#ffffff',
  primaryBorderColor: '#ddcc7c',
  secondaryBorderColor: '#e9ecef',
  primaryBorderRadius: '8px',
  secondaryBorderRadius: '4px',
  tertiaryBorderRadius: '9999px',
  rcBoxTabCase: 'capitalize',
  sidebarOrder: 0,


  showTopBar: false,
  topBar: {
    showTopContent: false,
    invertColor: false
  },
  logoSubText: false,
  showLearnMore: false,

};

const PARTNERS: { [key: string]: typeof DEFAULT_STYLES } = {
  'customize': {
    brandColor: '#095E4D',
    brandlogo: `url(${EmblemLogo})`,
    watermarkBrand: 'emblem',
    tabIcon: 'favicon.ico',
    tabTitle: '',
    enablePoweredBy: false,
    enableDefaultDone: false,
    enableMagicNoSearch: false,
    isStage: false,

    primaryFont: 'HK Grotesk',
    secondaryFont: 'HK Grotesk',
    primaryColor: '#000000',
    secondaryColor: '#323232',
    tertiaryColor: '#ffffff',
    primaryBgColor: '#f7f6f4',
    secondaryBgColor: '#ffffff',
    primaryBorderColor: '#e0baad',
    secondaryBorderColor: '#e9ecef',
    primaryBorderRadius: '8px',
    secondaryBorderRadius: '4px',
    tertiaryBorderRadius: '9999px',
    rcBoxTabCase: 'capitalize',
    sidebarOrder: 0,

    showTopBar: true,
    topBar: {
      showTopContent: true,
      invertColor: true
    },
    logoSubText: false,
    showLearnMore: true,
  },
  'shaneco': {
    ...DEFAULT_STYLES,

    watermarkBrand: '',
    brandColor: '#000000',
    brandlogo: `url(${shanecoLogo})`,
    tabIcon: 'ShaneCo-favicon.ico',
    enablePoweredBy: false,
    enableDefaultDone: false,
    enableMagicNoSearch: false,
    isStage: false,

    primaryFont: 'Montserrat',
    secondaryFont: 'Montserrat',
    primaryColor: '#000000',
    secondaryColor: '#202020',
  },
  'shaneco-pos': {
    ...DEFAULT_STYLES,

    watermarkBrand: '',
    brandColor: '#000000',
    brandlogo: `url(${shanecoLogo})`,
    tabIcon: 'ShaneCo-favicon.ico',
    enablePoweredBy: false,
    isStage: false,
  },
  'art-of-jewels': {
    ...DEFAULT_STYLES,

    watermarkBrand: '',
    brandColor: '#000000',
    brandlogo: `url(${ArtofJewelsLogo})`,
    tabIcon: '',
    enablePoweredBy: false,
    enableDefaultDone: false,
    isStage: false,
  },
  'yates': {
    ...DEFAULT_STYLES,

    brandColor: '#000000',
    brandlogo: `url(${YatesLogo})`,
    tabIcon: 'yates-favicon.ico',
    isStage: false,
  },
  'thinkspace': {
    ...DEFAULT_STYLES,

    brandColor: '#000000',
    isStage: false,
  },
  'dales' : {
    ...DEFAULT_STYLES,
    brandlogo: `url(${DalesLogo})`,
    tabIcon: 'dales-favicon.ico',

    isStage: false,
  },
  'mills' : {
    ...DEFAULT_STYLES,
    brandlogo: `url(${MillsLogo})`,
    tabIcon: 'mills-favicon.ico',
    brandColor: '#868eb6',

    isStage: false,
  },
  'helzberg': {
    ...DEFAULT_STYLES,
    brandlogo: `url(${HelzbergLogoPink})`,
    // brandlogoSecondary: `url(${HelzbergLogoPink})`,
    brandColor: '#a1204e',

    primaryFont: 'Tenor Sans',
    secondaryFont: 'Tenor Sans',
    primaryColor: '#846C7A',
    secondaryColor: '#846C7A',
    primaryBgColor: '#f6f4f2',
    primaryBorderColor: '#CEB0B2',
    secondaryBorderColor: '#CEB0B2',
    primaryBorderRadius: '0',
    secondaryBorderRadius: '0',
    tertiaryBorderRadius: '0',
    rcBoxTabCase: 'uppercase',
    sidebarOrder: 1,
  },
  'johnAtencio': {
    ...DEFAULT_STYLES,

    brandColor: '#1c1c1c',
    brandlogo: `url(${JohnAtencioLogo})`,
    tabIcon: 'johnAtencio-favicon.ico',

    primaryFont: 'futura-pt,sans-serif',
    secondaryFont: 'futura-pt,sans-serif',
    primaryColor: '#1c1c1c',
    secondaryColor: '#1c1c1c',
    primaryBgColor: '#f1f5f7',
    primaryBorderColor: '#ddcc7c',
    tertiaryBorderRadius: '0px',

    logoSubText: true,
  },
  'bellmans': {
    ...DEFAULT_STYLES,

    brandColor: '#AD172B',
    brandlogo: `url(${BellmansLogo})`,
    tabIcon: 'bellmans-favicon.ico',
  },
  'mavilo': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${MaviloLogo})`,
    tabIcon: 'mavilo-favicon.ico',
  },
  'thollot': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${ThollotLogo})`,
    tabIcon: 'thollot-favicon.ico',
  },
  'lamaison': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${LmdLogo})`,
    tabIcon: 'lamaison-favicon.ico',
  },
  'schubach': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${schubachLogo})`,
    tabIcon: 'schubach-favicon.ico',
  },
  'tqDiamonds': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${TQDiamondsLogo})`,
    tabIcon: 'tqDiamonds-favicon.ico',
  },
  'zaana': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${ZaanaLogo})`,
    tabIcon: 'zaana-favicon.ico',
  },
  'talisman': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${TalismanLogo})`,
    tabIcon: 'talisman-favicon.ico',
    enablePoweredBy: true,
  },
  'herzog': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${HerzogLogo})`,
    tabIcon: 'herzog-favicon.ico',
  },
  'dublin': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${DublinLogo})`,
    tabIcon: 'dublin-favicon.ico',
  },
  'carters': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${cartersLogo})`,
    tabIcon: '',
    tabTitle: 'Lashbrook Ring Builder - Cartersjewels',
    brandColor: "#512a44"
  },
  'jones-bros': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${JonesLogo})`,
    tabIcon: 'jones-favicon.ico',
  },
  "charleston-diamonds": {
    ...DEFAULT_STYLES,

    brandlogo: `url(${CharlestonLogo})`,
    tabIcon: 'charleston-favicon.ico',
  },
  'bella-cosa': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${BellaCosaLogo})`,
    tabIcon: 'bella-favicon.ico',
  },
  'michael-and-sons-jewelers': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${MichelAndSonsLogo})`,
    tabIcon: 'michael-and-sons-jewelers-favicon.ico',
    tabTitle: 'Lashbrook Ring Builder - Michael & Sons Jewellers'
  },
  'robert-palma-designs': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${RobertPalmLogo})`,
    tabIcon: 'robert-palma-favicon.png',
    tabTitle: 'Lashbrook Ring Builder - Robert Palma Designs'
  },
  'lashbrook-bands-at-reis-nichols': {
    ...DEFAULT_STYLES,

    brandlogo: `url(${ReisNicholosLogo})`,
    tabIcon: 'nicholos-favicon.ico',
  },
  "jd-smith": {
    ...DEFAULT_STYLES,

    brandlogo: `url(${JDSmithLogo})`,
    tabIcon: 'jd-smith-favicon.ico',
  },
  "dawn": {
    ...DEFAULT_STYLES,

    brandlogo: `url(${diamondsByDawnLogo})`,
    tabIcon: 'diamondsByDawn-favicon.ico',
  },
  "nasr": {
    ...DEFAULT_STYLES,

    brandlogo: `url(${nasrLogo})`,
    tabIcon: 'nasr-favicon.ico',
  }
};

let APP_STYLES: { [key: string]: typeof DEFAULT_STYLES }= {};

for (const prop in PARTNERS) {
  const style = PARTNERS[prop];
  if(style.isStage) {
    APP_STYLES[prop] = style;
    APP_STYLES[`${prop}-stage`] = style;
  } else {
    APP_STYLES[prop] = style;
  }
}

export  { APP_STYLES };