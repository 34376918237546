import clsx from "clsx";
import React from "react";
import {ITabs} from "../../types/customizer";
import listIcon from "../../../assets/images/list-icon.svg";
import rightArrowIcon from "../../../assets/images/right-arrow.svg";
import closeIcon from "../../../assets/images/close-icon.svg";

interface Props {
	pages: ITabs;
	selectedTab: string;
	handleActiveTab: Function;
	toggleOpen: React.MouseEventHandler<HTMLDivElement>;
	open: boolean;
}

const MobileSidebar = ({
  pages,
  selectedTab,
  handleActiveTab,
	toggleOpen,
	open
}: Props) => {

	return ( // Hide for sm devices 
		<div className="sm:tw-hidden">
			{!open && <div className="tw-absolute tw-left-3 tw-top-3 tw-cursor-pointer" onClick={toggleOpen}>
				<img src={listIcon} alt="list_icon" className="tw-inline"/>
				{/* <span className='tw-text-base tw-font-bold tw-ml-3 xs:tw-hidden md:tw-inline-block tw-text-primary-color'>All Steps</span> */}
			</div>}
			{open && (
				<div className="tw-bg-secondary-bg-color tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-border-t tw-border-gray-500 tw-h-full tw-overflow-y-auto">
					{pages.map(item =>
						<div key={item?.eventKey} className='tw-text-center md:tw-text-base tw-font-semibold tw-text-primary-color tw-cursor-pointer tw-py-3 tw-border-b tw-border-gray-500' onClick={handleActiveTab(item.eventKey, true)}>
              <span className={clsx('tw-relative sm:tw-w-64 tw-w-52 tw-inline-block', {'tw-underline': selectedTab === item.eventKey})}>
                {item.eventKey === selectedTab && <img src={rightArrowIcon} alt="list_icon" className="tw-absolute tw-top-0 tw-bottom-0 tw-m-auto tw--left-8"/>}
	              {typeof item.title === 'string' && item.title}
	              {typeof item.title === 'function' && item.title()}
              </span>
						</div>)
					}
					<div className="tw-absolute tw-left-3 tw-top-3 tw-cursor-pointer" onClick={toggleOpen}>
						<img src={closeIcon} alt="list_icon" className="tw-inline"/>
					</div>
				</div>)}
		</div>
	)
}

export default MobileSidebar;
