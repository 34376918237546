import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { ITab, ITabs } from '../../types/customizer';
import MyGallery from '../MyGallery';
import './index.scss';
import PrintSummary from '../PrintSummary';
import { TABS_TO_AVOID, getRowContent } from '../../../utils/summary';
import { useRingCustomizerContext } from '../../context/RingCustomizerContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';

type SummaryProps = {
  images?: string[];
  tabs: ITabs;
  product: any;
  isPDP?:boolean;
};


const Summary = ({ images = [], tabs, isPDP = false }: SummaryProps) => {
    const { product, subpath, params, isQueryHandling } = useRingCustomizerContext();
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    
    const componentRef = useRef<HTMLDivElement|null>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Summary',
        bodyClass: 'print-window',
    });

    const containerClass = clsx('tw-flex summary tw-secondary-font-family tw-text-primary-color', {
        'tw-overflow-y-auto tw-overflow-x-hidden tw-h-full tw-max-h-[calc(100% - 72px)] tw-py-5 lg:tw-py-10 lg:tw-pb-0 tw-px-4 xl:tw-px-32 swatch-filter-border': !isPDP,
        'tw-w-full tw-h-full tw-py-1 tw-pb-4 tw-bg-blue-50 tw-text-xs summary-pdp': isPDP,
    });

    const innerContainerClass = clsx({
        'tw-mx-auto lg:tw-mx-0 xl:tw-mr-20 tw-w-full lg:tw-w-1/2 tw-max-w-[520px]': !isPDP,
        'tw-w-full': isPDP,
    });

    const handleSearch = async() => {
        try {
            const response  = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/rings/searchMagicNumber?magicNumber=${value}`, {
              method: 'GET', 
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }
            }).then(res => res.ok ? res.json() : Promise.reject(res));
            const customizeUrl = response.ring_data?.customize_url;
            if(customizeUrl) {
                navigateToUrl(customizeUrl);
                setValue('');
            } else {
                toast.error(response.message)
            }
            console.log(response);
          } catch(error) {
            console.log(error)
          }
    }

    const navigateToUrl = (URL: string, replace = true) => {
        const { pathprefix, subpath} = params.pathParams;
        let navigateUrl = URL;
        if(pathprefix && subpath) {
          navigateUrl = `/${pathprefix}/${subpath}${URL}`;
        } else if(subpath){
          navigateUrl = `/${subpath}${URL}`;
        }
    
        if(isQueryHandling) {
          navigateUrl = `${location.pathname}?ring_url=${encodeURIComponent(navigateUrl)}`;
        }
    
        const otherQueryString = new URLSearchParams(params.otherQueryParams).toString();
        if(otherQueryString) {
          navigateUrl += `&${otherQueryString}`;
        }
        navigate(navigateUrl, { replace: replace }); // After URL is navigated it calls a useffect of location dependencies
      }

    const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
    return (
        <div className={containerClass}>
            <div className={innerContainerClass}>
                {!isPDP && (
                    <p>
                        {product?.description}
                    </p>
                )}
                <div id="summaryContent">
                    <div className="tw-mt-3 tw-text-lg tw-font-bold">Required</div>
                    <div className="summary-container tw-mt-3 tw-ml-3">
                        <div className="tw-mt-1">
                            <p>Magic Number</p>
                            <p>{product?.magic_number}</p>
                        </div>
                        {tabs
                            ?.filter((tab: ITab) => ['ringSize'].includes(tab.eventKey))
                            .map((tab: ITab, index) => (
                                <React.Fragment key={index}>{getRowContent(tab, index)}</React.Fragment>
                            ))}
                        {tabs
                            ?.filter((tab: ITab) => ['engraving'].includes(tab.eventKey))
                            .map((tab: ITab, index) => (
                                <React.Fragment key={index}>{getRowContent(tab, index)}</React.Fragment>
                            ))}
                    </div>
                    <div className="tw-mt-3 tw-text-lg tw-font-bold">Additional Details</div>
                    <div className="summary-container tw-mt-3 tw-ml-3">
                        <div>
                            <p>Style</p>
                            <p>{product?.sku}</p>
                        </div>
                        {tabs
                            ?.filter(
                                (tab: ITab) =>
                                    !TABS_TO_AVOID.includes(tab.eventKey)
                            )
                            .map((tab: ITab, index) => (
                                <React.Fragment key={index}>
                                    {getRowContent(tab, index, { isPDP })}
                                </React.Fragment>
                            ))}
                    </div>
                </div>
                {!isPDP && (<>
                    <button id="print-btn" className="tw-mt-8 !tw-bg-brand-color !tw-text-white hover:tw-text-white tw-px-9 tw-py-2 tw-font-medium tw-normal-case secondary-font-family focus:tw-outline-none" onClick={handlePrint}>Print Summary</button>
                    <PrintSummary tabs={tabs} image={images?.[0]} product={product} ref={componentRef} />
                </>)}
                {styles.enableMagicNoSearch && !isPDP && (<div className="tw-mt-8 tw-flex">
                    <input type='text' name='magic_number' placeholder='Search Magic number' value={value} onChange={e => {setValue(e.target.value);}} className="tw-p-3 tw-border tw-mr-3 !tw-text-base" />
                    <button id="print-btn" className="!tw-bg-brand-color !tw-text-white hover:tw-text-white tw-px-9 tw-py-2 tw-font-medium tw-normal-case tw-secondary-font-family focus:tw-outline-none" onClick={handleSearch}>Search</button>
                </div>)}
            </div>
            {!!images?.length && (
                <div className="tw-min-w-[300px] tw-flex-1 gallery_container tw-relative xs:tw-hidden lg:tw-block">
                    <MyGallery images={images} />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    product: state.customizer.data,
});

export default connect(mapStateToProps)(Summary);

