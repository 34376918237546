import { getArrangementItems } from "../../../utils/arrangementItems";
import { getFilterMetaData, getFontOptions, getSelectedOption, getWarning } from "../../../utils/commonRingUtils";
import { getFinishItems } from "../../../utils/finishItems";
import { getOutsideFeatureItems } from "../../../utils/outsideFeatureItems";
import { getSecondaryOutsideFeatureItems } from "../../../utils/secondaryOutsideFeatureItems";
import { getShapeItems } from "../../../utils/shapeItems";
import SizeFilter from "../../components/SizeFilter/SizeFilter";
import SwatchFilter from "../../components/SwatchFilter";
import { ITab, ITabs } from "../../types/customizer";
import SummaryInfo from "../../components/SummaryInfo/SummaryInfo";
import Summary from "../../components/Summary";
import { getEngravingItems } from "../../../utils/engravingItems";

export const getPageData = (product: any, subpath='customize') => {
  const items: ITabs = [
    {
      title: 'Material',
      eventKey: 'material',
      component: SwatchFilter,
      content: {
        label: 'Ring Material',
        hasSwatch: true,
        showDefaultTitle: false,
        displayShowMoreOption: false,
        fieldType: "ring_materials",
        options: product.ring_material,
        selectedOption: getSelectedOption(product.ring_material),
        meta: getFilterMetaData(product, 'ring_material'),
        warning: getWarning(product, 'ring_material'),
        isEnabled: product.ring_material?.length > 0,
      },
    },
    {
      title: 'Shape',
      eventKey: 'shape',
      items: getShapeItems(product),
      tabStyle: 'box',
    },
    {
      title: 'Finish',
      eventKey: 'finish',
      items: getFinishItems(product),
      tabStyle: 'box',
    },
    {
      title: 'Outside Feature',
      eventKey: 'outsideFeature_1',
      ...getOutsideFeatureItems(product),
      tabStyle: 'box',
    },
    {
      title: () => <>2<sup>nd</sup> Outside Feature</>,
      eventKey: 'outsideFeature_2',
      ...getSecondaryOutsideFeatureItems(product),
      tabStyle: 'box',
    },
    {
      title: 'Inside Feature',
      eventKey: 'insideFeature',
      component: SwatchFilter,
      content: {
        label: 'Inside Sleeve',
        hasSwatch: true,
        showDefaultTitle: false,
        displayShowMoreOption: false, // show more removed after review
        fieldType: "sleeve",
        options: product.sleeve,
        selectedOption: getSelectedOption(product.sleeve),
        meta: getFilterMetaData(product, 'sleeve'),
        warning: getWarning(product, 'sleeve'),
        isEnabled: product.sleeve?.length > 0,
      },
    },
    {
      title: 'Arrangement',
      eventKey: 'arrangement',
      items: getArrangementItems(product),
      tabStyle: 'box',
    },
    {
      title: 'Gemstone Details',
      eventKey: 'gemstone_1',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Type',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        fieldType: "gemstone_details_1",
        options: product.gemstone?.gemstone_1?.gemstone_material || [],
        selectedOption: getSelectedOption(product.gemstone?.gemstone_1?.gemstone_material),
        meta: getFilterMetaData(product, 'gemstone_material'),
        warning: getWarning(product, 'gemstone_material'),
        isEnabled: product.gemstone?.gemstone_1?.gemstone_material?.length > 0
      },
    },
    {
      title: () => <>2<sup>nd</sup> Gemstone Details</>,
      eventKey: 'gemstone_2',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Type',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        fieldType: "gemstone_details_2",
        options: product.gemstone?.gemstone_2?.gemstone_material,
        selectedOption: getSelectedOption(product.gemstone?.gemstone_2?.gemstone_material),
        meta: getFilterMetaData(product, 'gemstone_material'),
        warning: getWarning(product, 'gemstone_material'),
        isEnabled: product.gemstone?.gemstone_2?.gemstone_material?.length > 0
      },
    },
    {
      title: () => <>3<sup>rd</sup> Gemstone Details</>,
      eventKey: 'gemstone_3',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Type',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        fieldType: "gemstone_details_3",
        options: product.gemstone?.gemstone_3?.gemstone_material,
        selectedOption: getSelectedOption(product.gemstone?.gemstone_3?.gemstone_material),
        meta: getFilterMetaData(product, 'gemstone_material'),
        warning: getWarning(product, 'gemstone_material'),
        isEnabled: product.gemstone?.gemstone_3?.gemstone_material?.length > 0,
      },
    },
    {
      title: 'Engraving',
      eventKey: 'engraving',
      // disclaimer: "\"The customer's engraving as they have typed it, or nothing if not added\""
      tabStyle: 'box',
      ...getEngravingItems(product, product.engraving_new)
     },
     {
      title: 'Size',
      eventKey: 'ringSize',
      component: SizeFilter,
      content: {
        label: 'Ring Size',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false, // show more removed after review
        fieldType: "size",
        options: product.size || [],
        selectedOption: getSelectedOption(product.size),
        meta: getFilterMetaData(product, 'size'),
        warning: getWarning(product, 'size'),
        isEnabled: product.size?.length > 0,
      },
    },
    {
      title: 'Summary',
      eventKey: 'summary',
      component: Summary,
      content: {
          label: 'summary',
          hasSwatch: false,
          showDefaultTitle: true,
          displayShowMoreOption: false, // show more removed after review
          fieldType: 'summary',
          options: [],
          selectedOption: null,
          meta: null,
          warning: null,
          isEnabled: subpath !== 'customize',
      },
    },
    {
      title: 'Info',
      eventKey: 'summaryInfo',
      component: SummaryInfo,
      content: {
        label: 'summary Info',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false, // show more removed after review
        fieldType: "info",
        options: [],
        selectedOption: null,
        meta: null,
        warning: null,
        isEnabled: subpath === 'customize',
      },
    },
  ]

  const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}
