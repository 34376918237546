import clsx from "clsx";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { ICategoryOption, IOption } from "../../types/customizer";
import If from "../If";
import SwatchOption from "../SwatchOption";
import questionMark from '../../../assets/images/question-mark.svg';
import MaterialInfo from "./MaterialInfo";
import GroupMaterialInfo from "./GroupMaterialInfo";
import closeIcon from '../../../assets/images/close-icon.svg';
import DiamondInfo from "./DiamondInfo";
import PersonalizedMachinePatternInfo from './PersonalizedMachinePatternInfo';

interface ISwatchCategory extends ICategoryOption {
  hasSwatch: boolean;
  displayShowMoreOption: boolean;
  fieldType: string;
  onChange: (updated: any) => void;
  selectedOption: IOption;
  index: number;
  isChanging: string;
  isEngraving?: boolean; // used below keys only for engraving tab
  font?: IOption
  rootCategory?: boolean;
}

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    zIndex: 100,
    // marginRight: "-50%",
    minWidth: '300px',
    transform: "translate(-50%, -50%)",
  },
};

const SwatchCategory = ({ type, name, swatch_url, items, hasSwatch, displayShowMoreOption, fieldType, onChange, 
  selectedOption, index, isChanging, isEngraving, font, rootCategory, category_desc }: ISwatchCategory) => {
  
  const [enableMoreOption, setEnableMoreOption] = useState(false);
  const [seeMore, setSeeMore] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  
  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    if(displayShowMoreOption) {
      const isAnySubCategory = items.find(i => i.type === 'category');
      setEnableMoreOption(!isAnySubCategory && items.length > 5)
    }
  }, [displayShowMoreOption, items])
  
  const options = enableMoreOption && seeMore ? items.slice(0, 5) : items;
  const hasSubCategory = options.find(item => item.type === 'category') || rootCategory;
  return (
    <div className={clsx({
      "category-wrapper tw-mx-2 tw-pt-2 tw-w-full": true, 
      'tw-border-none': index === 0,
      'tw-border-y tw-border-dashed tw-border-gray-100': index !==0
    })}>
      {name && <div className="tw-flex primary-font-family tw-text-sm tw-font-semibold tw-text-primary-color">
        {name}
        {(name === 'Premium Diamond' || name === 'Standard Diamond' || name === 'Personalized') ? 
          <img onClick={openModal} src={questionMark} className="tw-pl-1" alt="none"/> : null}
      </div>}
      <div className={clsx('tw-flex tw-w-full tw-pr-3', { 'tw-flex-wrap': hasSubCategory, 'tw-overflow-x-auto': !hasSubCategory })}>
        {options.map((option: IOption | ICategoryOption, index: number) => {
            if(option.type === 'category') {
              return (
                <SwatchCategory
                  key={index}
                  {...option}
                  hasSwatch={hasSwatch}
                  displayShowMoreOption={displayShowMoreOption}
                  fieldType={fieldType}
                  onChange={onChange} 
                  selectedOption={selectedOption} 
                  index={index}
                  isChanging={isChanging}
                />
              );
            } else if(option.type === 'option' && !isEngraving) {
              return (
                <SwatchOption 
                  key={index}
                  {...option} 
                  hasSwatch={hasSwatch} 
                  displayShowMoreOption={displayShowMoreOption}
                  fieldType={fieldType}
                  onChange={onChange}
                  selectedOption={selectedOption}
                  isChanging={isChanging}
                  moreInfo={selectedOption?.description}
                />
              );
            } else if(option.type === 'option' && isEngraving) {
              return (
                <SwatchOption 
                  key={index}
                  {...option} 
                  selected={option.sku === font?.sku} // isEngraving then selected based on font selected
                  hasSwatch={hasSwatch} 
                  displayShowMoreOption={displayShowMoreOption}
                  fieldType={fieldType}
                  onChange={onChange}
                  selectedOption={selectedOption}
                  isChanging={isChanging}
                />
              );
            } else {
              return null
            }
        })}
        <If condition={enableMoreOption && hasSwatch}>
          <div className="tw-w-28 tw-h-28 tw-p-2.5 tw-mr-5 tw-mt-2" onClick={() => setSeeMore(!seeMore)}>
            <div className="tw-w-full tw-h-full tw-rounded-full tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-border tw-border-solid tw-border-cyan hover:tw-bg-cyan hover:tw-text-white tw-text-sm tw-text-cyan tw-font-semibold">
              {seeMore ? 'See More': 'See Less'}
            </div>
          </div>
        </If>
        <If condition={enableMoreOption && !hasSwatch}>
          <div className="tw-p-2 tw-cursor-pointer" onClick={() => setSeeMore(!seeMore)}>
            <div className="tw-p-2 tw-rounded-lg tw-cursor-pointer tw-border tw-border-solid tw-border-cyan hover:tw-bg-cyan hover:tw-text-white tw-text-sm tw-text-cyan tw-font-semibold">
              {seeMore ? 'See More': 'See Less'}
            </div>
          </div>
        </If>
      </div>
      {category_desc?.length && category_desc.length > 1 ?
        <GroupMaterialInfo 
          isOpen={modalIsOpen}
          onClose={closeModal}
          descriptions={category_desc}
        /> : null
      }
      {category_desc?.length && category_desc.length === 1 ?
        <Modal 
          isOpen={modalIsOpen}
          ariaHideApp={false}
          onRequestClose={closeModal}
          className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-secondary-bg-color tw-absolute tw-p-5"
          style={customStyles}
          overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
        > 
          <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" onClick={closeModal}/>
          {category_desc && category_desc[0] &&  <MaterialInfo {...category_desc[0]} />}
        </Modal> : null
      }
      {(name === 'Premium Diamond' || name === 'Standard Diamond') ? 
      <Modal 
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-secondary-bg-color tw-absolute tw-p-5"
        style={customStyles}
        overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
      > 
        <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" onClick={closeModal}/>
        <DiamondInfo />
      </Modal> : null
      }
      {(name === 'Personalized') ? 
      <Modal 
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-secondary-bg-color tw-absolute tw-p-5"
        style={customStyles}
        overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
      > 
        <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" onClick={closeModal}/>
        <PersonalizedMachinePatternInfo />
      </Modal> : null
      }
    </div>)
}

export default SwatchCategory;