import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import { useMutation, useQuery } from "react-query";
import { toast } from 'react-toastify';
import AppHTMLTag from '../components/AppHTMLTag';
import { IParams, IUrl } from '../components/RingCustomizerHOC';
import NoMatch from '../pages/NoMatch/NoMatch';
import { APP_STYLES } from '../../utils/basic';

type Props = {
  children: React.ReactNode;
  params: IParams;
  url: IUrl;
  type: number;
  subpath: string;
  isQueryHandling: boolean;
}

type ContextType = {
  product: any;
  params: IParams;
  url: IUrl;
  type: number,
  isQueryHandling: boolean,
  handleAddToCart: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  handleDone: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  isLoading: boolean,
  subpath: string,
  currency: string,
};

const RingCustomizerContext = createContext<ContextType>({
  product: null,
  params: {pathParams: {}, queryParams: {}, otherQueryParams: {}},
  url: {pathString: '', queryString: '', ring_url: ''},
  type: 1,
  isQueryHandling: false,
  handleAddToCart: () => {},
  handleDone: () => {},
  isLoading: false,
  subpath: '',
  currency: 'USD',
});

const RingCustomizerProvider = ({ children, subpath, params, url, type, isQueryHandling }: Props) => {
  const [product, setProduct] = useState<any>(null);
  const [storeData, setStoreData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const { data, isFetching, refetch } = useQuery(['PRODUCT_INFO'], async () =>  {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/${type === 1? 'v1': 'v2'}${url.ring_url}`, { headers: { subpath, 'currency-code': storeData?.currency || 'USD' } }).then(async res => {
      if(res.ok) {
        return res.json();
      } else {
        const text = await res.text()
        throw new Error(text);
      }
    })
  }, {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onError: (err: any) => {
      toast.error('Something went wrong on this ring combination!!!', { autoClose: 5000 });
    }
  });

  const addProduct = useMutation(({ URL, data }: any) => fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1${URL}`, 
  {
    method: 'POST', 
    body: JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      subpath: subpath || 'customize',
      'currency-code': storeData?.currency || 'USD'
    }
  }).then(res => res.ok ? res.json() : Promise.reject(res)));

  const moveToShopifyCart = useMutation((body: FormData) => fetch('/cart/add.js', { method: 'POST', body }).then(res => res.ok ? res.json() : Promise.reject(res)));

  const moveToMagentoCart = useMutation((id) => fetch(`/customcart/cart/add/id/${id}`, { method: 'GET' }).then(res => res.ok ? res.text() : Promise.reject(res)));

  useEffect(() => {
    if(Object.keys(params.pathParams).length === 0) {
      return;
    }
    if(!subpath) {
      setIsSubscribed(true);
    } else {
      const styles = APP_STYLES[subpath];
      if(styles) {
        fetchSubscriptionStatus();  // Fetch shopify client subscribed for ring builder
      } else {
        setIsSubscribed(true);
      }
    }
  }, [subpath, params]);

  useEffect(() => {
    if(isSubscribed) {
      if(url.ring_url) {
        refetch();
      }
    }
  }, [url.ring_url, refetch, isSubscribed]);

  useEffect(() => {
    if(data) {
      setProduct(data);
    }
  }, [data]);

  const getAddProductURL = () => {
    const URL = `${url.pathString}/cart?${url.queryString}`;
    const element = document.getElementById('summaryContent');
    let body: any = {
      magic_number: data?.magic_number,
      summaryContent: element?.outerHTML
    };

    if(['wordpress', 'php', 'magento'].includes(storeData.platform_name)) {
      const integration_url = `https://${storeData.base_url}lashbrook-redesign?ring_url=${encodeURIComponent(`/apps/${storeData.subpath}${url.ring_url}`)}`;
      console.log(integration_url, url);
      body.integration_url = integration_url;
    }

    return [URL, body];
  }


  const handleDone = () => {
    /**
     * Get product cart URL and move to PDP
     */
    if(['shaneco-pos', 'yates', 'thinkspace'].includes(subpath)) {
      shanceCoAddToCart();
    } else {
      addProductAndRedirect();
    }
  }

  const shanceCoAddToCart = async() => {
    try {
      setLoading(true);
      const [ url, body ] = getAddProductURL();
      const URL = `${url}&magic_number=${body?.magic_number}`;
      const response  = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1${URL}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          subpath: subpath || 'customize',
          'currency-code': storeData?.currency || 'USD'
        }
      }).then(res => res.ok ? res.json() : Promise.reject(res)); 
      setLoading(false);

      const message = { action: 'addToCart', arguments: response };
      window.parent.postMessage(message, '*');
      console.log('message:', message);
    } catch(error) {
      setLoading(false);
      console.log(error)
    }
  }

  const addProductAndRedirect = () => {
    const [URL, body] = getAddProductURL();

    addProduct.mutate({ URL: URL, data: body }, {
      onSuccess: redirectToPDP
    });
  }

  const redirectToPDP = (response: any) => {
    if(response.result) {
      console.log('>>>>ADDED PRODUCT>>>>>', response);
      if(process.env.REACT_APP_ENVIRONMENT === 'localhost') {
        return;
      }
      if(['wordpress', 'php'].includes(storeData.platform_name)) {
        window.location.replace(response.details.permalink);
        return;
      } else if(storeData.platform_name === 'shopify') {
        const handle = response.details.handle;
        window.location.replace(`/products/${handle}`);
        return;
      } else if(storeData.platform_name === 'magento') {
        console.log('>>>>MAGENTO>>>>>', response);
        const product_id = response.details.id;
        window.location.replace(`/catalog/product/view/id/${product_id}`);
      }
    } else {
      toast.error(response.message);
      return;
    }
  }


  const handleAddToCart = () => {
    const [URL, body] = getAddProductURL();

    addProduct.mutate({ URL: URL, data: body }, { onSuccess: (response: any) => {
      if(storeData.platform_name === 'shopify') {
        redirectToShopifyCart(response)
      } else if(storeData.platform_name === 'magento') {
        redirectToMagentoCart(response);
      }
    }, onError: (err) => {
      toast.error('Something went wrong. Please contact the administrator')
    }});
  }

  const redirectToShopifyCart = (response: any) => {
    if(process.env.REACT_APP_ENVIRONMENT === 'localhost') {
      return;
    }
    if(response.result) {
      var _learnq: any = (window as any)._learnq|| [];
      var item = {
        Name: response.details.title,
        ProductID: response.details.product_id,
        Sku: response.details.sku,
        Price: response.details.price,
        URL: window.location.href
      };

      _learnq.push(['track', 'Added to Cart', item]);
      _learnq.push(['trackCartItem', {
        Title: item.Name,
        ItemId: item.ProductID,
        Metadata: {
          Price: item.Price,
        }}
      ]);
      const formData = new FormData();
      formData.append('items[0][id]', response.productId);
      formData.append('items[0][quantity]', '1');
      /**
       * Move product to cart and redirect to /cart
       */
      moveToShopifyCart.mutate(formData,  {
        onSuccess: () => {
          window.location.replace("/cart");
        }
      })
    } else {
      toast.error(response.message, {autoClose: 5000});
      return;
    }
  }

  const redirectToMagentoCart = (response: any) => {
    const product_id = response.details.id;
    moveToMagentoCart.mutate(product_id, {
      onSuccess: (response) => {
        window.location.replace('/checkout/cart');
      }
    })
  }


  const fetchSubscriptionStatus = useCallback(async() => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/admin/v1/builder-platform/status`,
      { 
        method: 'POST',
        body: JSON.stringify({ subpath: subpath || 'customize' }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const res = await response.json();
      setLoading(false);
      
      if(!res.result && res.message) {
        toast.info(res.message);
        return;
      }
      setStoreData(res);
      setIsSubscribed(!!res.is_approved);
      if(res.subpath && !res.is_approved) {
        toast.error('Subscription not active to use ring builder.');
        setTimeout(() => {
          window.location.replace(window.location.origin);
        }, 3000);
      }
    } catch(error) {
      setLoading(false);
      toast.error('Something went wrong to fetch subscription status');
    }
  }, [subpath]);

  return <RingCustomizerContext.Provider value={{
    product: product,
    params,
    url,
    type,
    isQueryHandling,
    handleAddToCart,
    handleDone,
    isLoading: loading || isFetching || addProduct?.isLoading || moveToShopifyCart?.isLoading,
    subpath: subpath || '',
    currency: storeData?.currency || 'USD'
  }}>
    <AppHTMLTag subpath={subpath} />
    {!loading && !isSubscribed ? <NoMatch/> : children}
  </RingCustomizerContext.Provider>;
};

export default RingCustomizerProvider;

export const useRingCustomizerContext = () => {
  const ringCustomizerContext = useContext(RingCustomizerContext);

  if (!ringCustomizerContext) {
    throw new Error('useRingCustomizerContext() has to be used within a child of the RingCustomizer Provider');
  }

  return {
   ...ringCustomizerContext
  };
};
