import { useEffect, useState } from 'react';
import { ISwatchContent } from '../../types/customizer';
import SwatchCategory from '../SwatchCategory';

export interface INoneFilterProps extends ISwatchContent {
  onChange: (updated: any) => void;
  innerTab?: number; 
  isFetching: boolean;
 }

const NoneTabFilter = ({ label, showDefaultTitle, options, images, hasSwatch, displayShowMoreOption, fieldType, 
  selectedOption, meta, warning, onChange, isEnabled, isFetching, innerTab = 1 }:INoneFilterProps) => {
    const [isChanging, setIsChanging] = useState('');

  useEffect(() => {
    if(!isFetching) {
      setIsChanging('');
    }
  }, [isFetching]);

  const handleChange = (updatedObj: {[name: string]: string}) => {
    setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
    onChange(updatedObj);
  }

  return (
    <div className="tw-flex tw-flex-col tw-pb-2 tw-justify-center tw-items-center tw-h-full swatch-filter-border">
      <div className='lg:tw-pt-8 xs:tw-pt-2 sm:tw-pt-4 md:tw-pt-6 tw-text-center lg:tw-text-base md:tw-text-lg xs:tw-text-base tw-w-4/6 tw-text-primary-color'>
        <p>Add a feature to the outside of your ring</p>
      </div>
      <div className="tw-flex tw-justify-center tw-h-full tw-overflow-auto">
        <SwatchCategory
          type='category'
          name=''
          swatch_url=''
          items={options}
          hasSwatch={hasSwatch}
          displayShowMoreOption={displayShowMoreOption}
          fieldType={fieldType}
          onChange={handleChange}
          selectedOption={selectedOption}
          rootCategory={true}
          index={0}
          isChanging={isChanging}
        />
      </div>
			{/* <img src={imageUrl} alt="current_ring" className='tw-m-1 tw-rounded-full sm:tw-h-22.5 sm:tw-w-22.5 xs:tw-h-16 xs:tw-w-16'></img> */}
    </div>
  )
}

export default NoneTabFilter