import SwatchFilter from "../app/components/SwatchFilter";
import { ITab, ITabs } from "../app/types/customizer";
import { getFilterMetaData, getSelectedOption, getWarning } from "./commonRingUtils";

export const getFinishItems = (product: any) => {
  const items: ITabs = []
  const finishes = product.ring_finish || []
  finishes.map((finish: any, id: any) => {
    items.push(
      {
        title: finish.name,
        eventKey: finish.name + id,
        component: SwatchFilter,
        content: {
          label: finish.name,
          hasSwatch: true,
          showDefaultTitle: true,
          displayShowMoreOption: false,
          isEnabled: finish.items?.length > 0,
          fieldType: `ring_${id}`,
          options: finish.items,
          selectedOption: getSelectedOption(finish.items),
          meta: getFilterMetaData(product, 'ring_finish'),
          warning: getWarning(product, `ring_finish_${id}`)
        }
      }
    );
  })
    const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}